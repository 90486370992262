import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'links';

/**
 * TODO: 
 */
export const loadLinksList = fasterActions(moduleName, 'loadLinksList');
export const loadLinkSelections = fasterActions(moduleName, 'loadLinkSelections');
export const createLink = fasterActions(moduleName, 'createLink');


export const loadTencentBsp = fasterActions(moduleName, 'loadTencentBsp');
export const createTencentBsp = fasterActions(moduleName, 'createTencentBsp');
export const editTencentBsp = fasterActions(moduleName, 'editTencentBsp');

export const umit = fasterActions(moduleName, 'umit');

// 字段管理
export const linksFields = fasterActions(moduleName, 'linksFields');
export const linksFieldsSelections = fasterActions(moduleName, 'linksFieldsSelections');
export const linksFieldsStatus = fasterActions(moduleName, 'linksFieldsStatus');
export const linksFieldsModify = fasterActions(moduleName, 'linksFieldsModify');

// 环节管理
export const links = fasterActions(moduleName, 'links');
export const linksStatus = fasterActions(moduleName, 'linksStatus');
export const linksModify = fasterActions(moduleName, 'linksModify');

export const loadCodeLinksList = fasterActions(moduleName, 'loadCodeLinksList');
export const loadDiEnterpirsesLink = fasterActions(moduleName, 'loadDiEnterpirsesLink');

export const linksenterprise = fasterActions(moduleName, 'linksenterprise');
export const linksenterpriseId = fasterActions(moduleName, 'linksenterpriseId');
export const createenterpriseLink = fasterActions(moduleName, 'createenterpriseLink');

export const tagstatusSelections = fasterActions(moduleName, 'tagstatusSelections');

export const isvLinkGet = fasterActions(moduleName, 'isvLinkGet');
export const isvLinkSave = fasterActions(moduleName, 'isvLinkSave');
export const isvLinkDelete = fasterActions(moduleName, 'isvLinkDelete');

// 环节草稿
export const getLinkDraftList = fasterActions(moduleName, 'getLinkDraftList');
// 环节审核列表
export const getLinkVerifyList = fasterActions(moduleName, 'getLinkVerifyList');
// 环节审核发布
export const handlePublishLink = fasterActions(moduleName, 'handlePublishLink');
// 环节草稿提交及环节审核发布
export const handleCommitAndSubmit = fasterActions(moduleName, 'handleCommitAndSubmit');
// 环节历史
export const getLinkHistoryList = fasterActions(moduleName, 'getLinkHistoryList');
// 环节历史详情
export const getLinkHistoryDetail = fasterActions(moduleName, 'getLinkHistoryDetail');
// 环节删除
export const handleDeleteLink = fasterActions(moduleName, 'handleDeleteLink');
// 环节已发布列表
export const getLinkPublishedList = fasterActions(moduleName, 'getLinkPublishedList');

export const changeDictPaging = createAction(`${moduleName}/changeDictPaging`);


// 环节管理新版

//新增方案
export const addLinkPlan = fasterActions(moduleName, 'addLinkPlan');
//排序方案
export const sortLinkPlan = fasterActions(moduleName, 'sortLinkPlan');
//方案不分页
export const linkPlanSelect = fasterActions(moduleName, 'linkPlanSelect');
//搜索特定分组
export const linkPlanTypeValue = fasterActions(moduleName, 'linkPlanTypeValue');

export const linkPlanCheckRule = fasterActions(moduleName, 'linkPlanCheckRule');
//清空数据
export const unmount = fasterActions(moduleName, 'unmount');

export const getAllLinkField = fasterActions(moduleName, 'getAllLinkField');

// 新版环节编辑
export const linkEditPublished = fasterActions(moduleName, 'linkEditPublished');

export const changeLinkPlanSelection = createAction(`${moduleName}/changeLinkPlanSelection`);
const initialState = {

  dictPaging: {
    page: 0,
    size: 20,
  },

  LinksList: [],
  loadLinksListLoading: false,
  loadLinksListLoaded: false,
  linksSelectionsList: [],
  linksSelectionsList2: [],
  tencentBspContent: '',
  allLinkFieldList: [],
  CodeLinksList: [],
  isvLinkList: [],
  linkPlanRule: [],
  linkPlanfilterValue: '',
  linkDraftList: [],
  linkVerifyList: [],
  linkHistoryList: {},
  linkHistoryDetail: {},
  linkPublishedList: [],
  linkPlanSelection: [],
}

const reducer = handleActions(
  {
    [changeDictPaging]: (state, { payload }) => {
      return { ...state, dictPaging: { ...payload } }
    },
    // changeLinkPlanSelection
    [changeLinkPlanSelection]: (state, { payload }) => {
      return { ...state, linkPlanSelection: payload }
    },
    //企业列表
    [loadLinksList.REQUEST]: (state, { payload }) => ({ ...state, loadLinksListLoading: true }),
    [loadLinksList.SUCCESS]: (state, { payload }) => ({ ...state, loadLinksListLoading: false, loadLinksListLoaded: true, LinksList: payload }),
    [loadLinksList.FAIL]: (state, { payload }) => ({ ...state, loadLinksListLoading: false, loadLinksListLoaded: false, error: payload }),

    [getAllLinkField.REQUEST]: (state, { payload }) => ({ ...state, }),
    [getAllLinkField.SUCCESS]: (state, { payload }) => ({ ...state, allLinkFieldList: payload, }),
    [getAllLinkField.FAIL]: (state, { payload }) => ({ ...state, }),

    [linkEditPublished.REQUEST]: (state, { payload }) => ({ ...state, }),
    [linkEditPublished.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [linkEditPublished.FAIL]: (state, { payload }) => ({ ...state, }),

    // select
    [loadLinkSelections.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadLinkSelections.SUCCESS]: (state, { payload }) => {
      const selector = [];
      const selector2 = [];
      if (payload) {
        for (let elem of payload.values()) {
          selector.push({ ...elem, id: elem.id, code: elem.code, text: elem.name + '_' + elem.code, fields: elem.fields });
          selector2.push({ ...elem, id: elem.id, code: elem.code, value: elem.id, text: elem.name, linkType: elem.linkType });
        }
      }
      return ({ ...state, linksSelectionsList: selector, linksSelectionsList2: selector2 });
    },
    [loadLinkSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [createLink.REQUEST]: (state, { payload }) => ({ ...state }),
    [createLink.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createLink.FAIL]: (state, { payload }) => ({ ...state }),

    [createenterpriseLink.REQUEST]: (state, { payload }) => ({ ...state }),
    [createenterpriseLink.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createenterpriseLink.FAIL]: (state, { payload }) => ({ ...state }),


    [loadTencentBsp.REQUEST]: (state, { payload }) => ({ ...state }),
    [loadTencentBsp.SUCCESS]: (state, { payload }) => ({ ...state, tencentBspContent: payload ? payload : [] }),
    [loadTencentBsp.FAIL]: (state, { payload }) => ({ ...state }),
    [createTencentBsp.REQUEST]: (state, { payload }) => ({ ...state }),
    [createTencentBsp.SUCCESS]: (state, { payload }) => ({ ...state }),
    [createTencentBsp.FAIL]: (state, { payload }) => ({ ...state }),
    [editTencentBsp.REQUEST]: (state, { payload }) => ({ ...state }),
    [editTencentBsp.SUCCESS]: (state, { payload }) => ({ ...state }),
    [editTencentBsp.FAIL]: (state, { payload }) => ({ ...state }),

    [linksFields.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [linksFields.SUCCESS]: (state, { payload }) => ({ ...state, linksFields: payload, loading: false }),
    [linksFields.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [linksFieldsSelections.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [linksFieldsSelections.SUCCESS]: (state, { payload }) => {
      if (payload.content) {
        payload.content.forEach(v => { v.text = v.name, v.value = v.id });
      }
      return ({ ...state, linksFieldsSelections: payload });
    },
    [linksFieldsSelections.FAIL]: (state, { payload }) => ({ ...state }),

    [linksFieldsModify.REQUEST]: (state, { payload }) => ({ ...state, payload, linksFieldsModifyLoaded: false }),
    [linksFieldsModify.SUCCESS]: (state, { payload }) => ({ ...state, linksFieldsModifyLoaded: payload, }),
    [linksFieldsModify.FAIL]: (state, { payload }) => ({ ...state }),

    [linksFieldsStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, linksFieldsStatusLoaded: false }),
    [linksFieldsStatus.SUCCESS]: (state, { payload }) => ({ ...state, linksFieldsStatusLoaded: payload, }),
    [linksFieldsStatus.FAIL]: (state, { payload }) => ({ ...state }),


    [links.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [links.SUCCESS]: (state, { payload }) => ({ ...state, links: payload, loading: false }),
    [links.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [linksenterprise.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [linksenterprise.SUCCESS]: (state, { payload }) => ({ ...state, linksenterprise: payload, loading: false }),
    [linksenterprise.FAIL]: (state, { payload }) => ({ ...state, loading: false }),

    [linksenterpriseId.REQUEST]: (state, { payload }) => ({ ...state, payload }),
    [linksenterpriseId.SUCCESS]: (state, { payload }) => ({ ...state, linksenterpriseId: payload }),
    [linksenterpriseId.FAIL]: (state, { payload }) => ({ ...state }),



    [linksModify.REQUEST]: (state, { payload }) => ({ ...state, payload, linksModifyLoaded: false }),
    [linksModify.SUCCESS]: (state, { payload }) => ({ ...state, linksModifyLoaded: payload, }),
    [linksModify.FAIL]: (state, { payload }) => ({ ...state }),

    [linksStatus.REQUEST]: (state, { payload }) => ({ ...state, payload, linksStatusLoaded: false }),
    [linksStatus.SUCCESS]: (state, { payload }) => ({ ...state, linksStatusLoaded: payload, }),
    [linksStatus.FAIL]: (state, { payload }) => ({ ...state }),

    [umit.REQUEST]: (state, { payload }) => ({ ...state, payload, tencentBspContent: '' }),

    [loadCodeLinksList.REQUEST]: (state, { payload }) => ({ ...state, }),
    [loadCodeLinksList.SUCCESS]: (state, { payload }) => ({ ...state, CodeLinksList: payload }),
    [loadCodeLinksList.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [tagstatusSelections.REQUEST]: (state, { payload }) => ({ ...state, }),
    [tagstatusSelections.SUCCESS]: (state, { payload }) => {
      let tagstatusSelections = [];
      for (let elem of payload.content) {
        const obj = { ...elem, value: elem.code, text: elem.name, id: elem.code }
        tagstatusSelections.push(obj);
      }

      return { ...state, tagstatusSelections }
    },

    [tagstatusSelections.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [loadDiEnterpirsesLink.REQUEST]: (state, { payload }) => ({ ...state, }),
    [loadDiEnterpirsesLink.SUCCESS]: (state, { payload }) => {
      let selector = [];
      if (payload.content) {
        payload.content.forEach(v => { if (v.link.id) { selector.push({ ...v, text: v.link.name, value: v.link.code }) } });
      }
      return ({ ...state, diEnterpirsesLinkList: selector })
    },
    [loadDiEnterpirsesLink.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [isvLinkGet.REQUEST]: (state, { payload }) => ({ ...state, }),
    [isvLinkGet.SUCCESS]: (state, { payload }) => ({ ...state, isvLinkList: payload }),
    [isvLinkGet.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [isvLinkSave.REQUEST]: (state, { payload }) => ({ ...state, }),
    [isvLinkSave.SUCCESS]: (state, { payload }) => ({ ...state, isvLinkSave: payload }),
    [isvLinkSave.FAIL]: (state, { payload }) => ({ ...state, error: payload }),


    [isvLinkDelete.REQUEST]: (state, { payload }) => ({ ...state, }),
    [isvLinkDelete.SUCCESS]: (state, { payload }) => ({ ...state, isvLinkDelete: payload }),
    [isvLinkDelete.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // 环节管理新版
    [addLinkPlan.REQUEST]: (state, { payload }) => ({ ...state, }),
    [addLinkPlan.SUCCESS]: (state, { payload }) => ({ ...state, }),
    [addLinkPlan.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    //获取环境方案
    [linkPlanSelect.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [linkPlanSelect.SUCCESS]: (state, { payload }) => {
      let linkPlanTypeValueList = payload.filter(key => { return (key.name || '').indexOf(state.linkPlanfilterValue) >= 0 || (key.id.toString()).indexOf(state.linkPlanfilterValue) >= 0 });
      return { ...state, linkPlanSelection: linkPlanTypeValueList, linkPlanAllSelection: payload, loading: false }

    },

    [linkPlanTypeValue.REQUEST]: (state, { payload }) => {
      let linkPlanTypeValueList = state.linkPlanAllSelection.filter(key => { return (key.name || '').indexOf(payload) >= 0 || (key.id.toString()).indexOf(payload) >= 0 });
      return { ...state, linkPlanfilterValue: payload, linkPlanSelection: linkPlanTypeValueList }
      // return { ...state, PlanfilterValue: payload }
    },


    [linkPlanSelect.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),


    //排序环节方案
    [sortLinkPlan.REQUEST]: (state, { payload }) => ({ ...state }),
    [sortLinkPlan.SUCCESS]: (state, { payload }) => ({ ...state }),
    [sortLinkPlan.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    //RULE
    [linkPlanCheckRule.REQUEST]: (state, { payload }) => ({ ...state, }),
    [linkPlanCheckRule.SUCCESS]: (state, { payload }) => ({ ...state, linkPlanRule: payload }),
    [linkPlanCheckRule.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    [unmount.REQUEST]: (state, { payload }) => ({
      linkPlanSelection: [],
      linkPlanfilterValue: '',

    }),

    [getLinkDraftList.REQUEST]: (state, { payload }) => ({ ...state }),
    [getLinkDraftList.SUCCESS]: (state, { payload }) => ({ ...state, linkDraftList: payload }),
    [getLinkDraftList.FAIL]: (state, { payload }) => ({ ...state }),

    [getLinkVerifyList.REQUEST]: (state, { payload }) => ({ ...state }),
    [getLinkVerifyList.SUCCESS]: (state, { payload }) => ({ ...state, linkVerifyList: payload }),
    [getLinkVerifyList.FAIL]: (state, { payload }) => ({ ...state }),

    [getLinkHistoryList.REQUEST]: (state, { payload }) => ({ ...state }),
    [getLinkHistoryList.SUCCESS]: (state, { payload }) => ({ ...state, linkHistoryList: payload }),
    [getLinkHistoryList.FAIL]: (state, { payload }) => ({ ...state }),

    [getLinkHistoryDetail.REQUEST]: (state, { payload }) => ({ ...state }),
    [getLinkHistoryDetail.SUCCESS]: (state, { payload }) => ({ ...state, linkHistoryDetail: payload }),
    [getLinkHistoryDetail.FAIL]: (state, { payload }) => ({ ...state }),

    [handleCommitAndSubmit.REQUEST]: (state, { payload }) => ({ ...state }),
    [handleCommitAndSubmit.SUCCESS]: (state, { payload }) => ({ ...state }),
    [handleCommitAndSubmit.FAIL]: (state, { payload }) => ({ ...state }),

    [handleDeleteLink.REQUEST]: (state, { payload }) => ({ ...state }),
    [handleDeleteLink.SUCCESS]: (state, { payload }) => ({ ...state }),
    [handleDeleteLink.FAIL]: (state, { payload }) => ({ ...state }),

    [handlePublishLink.REQUEST]: (state, { payload }) => ({ ...state }),
    [handlePublishLink.SUCCESS]: (state, { payload }) => ({ ...state }),
    [handlePublishLink.FAIL]: (state, { payload }) => ({ ...state }),

    [getLinkPublishedList.REQUEST]: (state, { payload }) => ({ ...state, loading: true }),
    [getLinkPublishedList.SUCCESS]: (state, { payload }) => ({ ...state, linkPublishedList: payload, loading: false }),
    [getLinkPublishedList.FAIL]: (state, { payload }) => ({ ...state, loading: false }),
  },
  initialState
);

export default reducer;
