import { of } from 'rxjs';
import { ofType } from 'redux-observable';
import { mergeMap, map, catchError, concatMap } from 'rxjs/operators';
//不要encode
import { ajax } from 'rxjs/ajax';

import urls from '@/api/urls';
import * as uiActions from '../ui';
import queryString from 'query-string';
import * as actions from './codeBatchQuery';
import { ajaxBaseConfig, actionApi } from '../../utils';
import { apiBaseConfig } from '@/redux/utils';

export const logEpic = actions$ => actions$.pipe(
  ofType(actions.log.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { page = 0, size = 20 } = action.payload;
    
    return ajax({
      ...ajaxBaseConfig,
      url: `${urls.codeBatchQuery.log}?page=${page}&size=${size}`,
      method: 'GET'
    }).pipe(
      map(res => actions.log.SUCCESS(res.response)),
      catchError(error => of(actions.log.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const queryEpic = actions$ => actions$.pipe(
  ofType(actions.query.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    const { file, queryValue=[], tagTypeEnum = '', queryTypeEnum = '', callback, jncTagLevelEnums=[]} = action.payload;
    
    file.append('queryValue', queryValue.join(','))
    file.append('queryTypeEnum', queryTypeEnum);
    file.append('tagTypeEnum', tagTypeEnum);
    file.append('jncTagLevelEnums', jncTagLevelEnums.join(','));

    return ajax({
      ...{
        headers: { 'X-CSRF-TOKEN': window.__INITIAL_STATE__.backend['X-CSRF-TOKEN'] || '', 'X-TC-TOKEN': window.__INITIAL_STATE__.backend['X-TC-TOKEN'] || '' },
        withCredentials: true,
      },
      url: `${urls.codeBatchQuery.query}`,
      method: 'POST',
      body: file,
      //   queryValue

    }).pipe(
      map(res => {
        if (res.status === 200) {
          callback && callback();
        }
        return actions.query.SUCCESS(res.response)
      }),
      catchError(error => of(actions.query.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

export const orderQueryEpic = actions$ => actions$.pipe(
  ofType(actions.orderQuery.REQUEST.toString()),
  mergeMap(action => {
    actionApi()
    let { file, queryValue, tagTypeEnum = '', queryTypeEnum = '', callback,jncTagLevelEnums=[],startTime,endTime } = action.payload;

    if(file){
      file.append('queryValue', queryValue.join(','))
      file.append('queryTypeEnum', queryTypeEnum);
      file.append('tagDownloadQuery.orderTypeEnum', tagTypeEnum);
      file.append('tagDownloadQuery.jncTagLevelEnums', jncTagLevelEnums.join(','));
    }else{
      file = {
        queryValue:queryValue.join(','),
        queryTypeEnum,
        'tagDownloadQuery.orderTypeEnum':tagTypeEnum,
        'tagDownloadQuery.jncTagLevelEnums':jncTagLevelEnums.join(','),
        'tagDownloadQuery.startTime':startTime,
        'tagDownloadQuery.endTime':endTime,
      }
    }
    
    return ajax({
      ...{
        headers: { 'X-CSRF-TOKEN': window.__INITIAL_STATE__.backend['X-CSRF-TOKEN'] || '', 'X-TC-TOKEN': window.__INITIAL_STATE__.backend['X-TC-TOKEN'] || '' },
        withCredentials: true,
      },
      url: `${urls.codeBatchQuery.orderQuery}`,
      method: 'POST',
      body: file,
      //   queryValue

    }).pipe(
      map(res => {
        if (res.status === 200) {
          callback && callback();
        }
        return actions.query.SUCCESS(res.response)
      }),
      catchError(error => of(actions.query.FAIL(error.xhr.response), uiActions.notification(error.xhr.response)))
    )
  }
  )
);

