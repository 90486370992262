import { createAction, createActions, handleActions, combineActions } from 'redux-actions';
import { fasterActions } from '../../utils';
import urls from '@/api/urls';

const moduleName = 'merchant';

/**
 * TODO: 商户号
 */

export const merchant = fasterActions(moduleName, 'merchant');
export const merchantById = fasterActions(moduleName, 'merchantById');
export const merchantModify = fasterActions(moduleName, 'modifymerchant');
export const v3MerchantModify = fasterActions(moduleName, 'v3Modifymerchant');
export const loadMerchantSelectioins = fasterActions(moduleName, 'loadMerchantSelectioins');



const initialState = {

}

const reducer = handleActions(
  {

    [merchant.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [merchant.SUCCESS]: (state, { payload }) => ({ ...state, merchant: payload, loading: false }),
    [merchant.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [merchantById.REQUEST]: (state, { payload }) => ({ ...state, payload, loading: true }),
    [merchantById.SUCCESS]: (state, { payload }) => ({ ...state, merchantDetail: payload, loading: false }),
    [merchantById.FAIL]: (state, { payload }) => ({ ...state, error: payload, loading: false }),

    [merchantModify.REQUEST]: (state, { payload }) => ({ ...state, payload, merchantModifyLoaded: false }),
    [merchantModify.SUCCESS]: (state, { payload }) => ({ ...state, merchantModifyLoaded: payload }),
    [merchantModify.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

    // v3弹窗
    [v3MerchantModify.REQUEST]: (state, { payload }) => ({ ...state, payload, v3MerchantModifyLoaded: false }),
    [v3MerchantModify.SUCCESS]: (state, { payload }) => ({ ...state, v3MerchantModifyLoaded: payload }),
    [v3MerchantModify.FAIL]: (state, { payload }) => ({ ...state, error: payload }),
        
    [loadMerchantSelectioins.REQUEST]: (state, { payload }) => ({ ...state, payload, merchantSelectionLoaded: false }),
    [loadMerchantSelectioins.SUCCESS]: (state, { payload }) => {
      let selects = [];
      if(payload && payload.content){
        payload.content.map(items =>{
          selects.push(
            { ...items, value: items.merchantId, text: items.merchantId + (items.remark ? '(' + items.remark + ')' : '') }
          )
        })
      }
      return ({ ...state, merchantSelections: selects })
    },
    [loadMerchantSelectioins.FAIL]: (state, { payload }) => ({ ...state, error: payload }),

  },

  initialState
);

export default reducer;
